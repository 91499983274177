<template>
  <div class="minha-conta">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>

          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div class="bg-gradient">
      <div class="container text-center pt-3">
        <h3>Liberar e-mail</h3>
        <div class="h-line" style="width:142px;"></div>
      </div>
    </div>
    <div class="row center">
      <div class="col-md-4 mt-5">
        <ValidationObserver v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="salvar">
            <div class="form-group">
              <label>E-mail</label>
              <ValidationProvider name="email" rules="required|email" v-slot="{ errors, classes }">
                <input v-model="email" type="e-mail" class="form-control" :class="classes"
                  placeholder="Email do cliente" />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>


            <button class="btn btn-success btn-block" type="submit" :disabled="loading || invalid">
              <span v-if="loading">Liberando o e-mail...</span>
              <span v-else>Liberar e-mail para cotação</span>
            </button>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</template>

<script>
import Swal from "sweetalert2";
import config from "../../config";
import axios from "axios";
import SideNav from "../../components/Admin/SideNav";
export default {
  components: { SideNav, },
  data() {
    return {
      config,
      loading: false,
      email: undefined,
    };
  },

  watch: {

  },
  methods: {

    async salvar() {
      try {
        this.loading = true;

        var result = await Swal.fire({
          text: "Deseja realmente liberar o e-mail para realizar a cotação? ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#28a745",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (result?.isConfirmed == false || result?.dismiss == "cancel") {
          this.loading = false;
          return;
        }

        var data = { email: this.email };

        await axios.post(
          `${config.API_URLV2}/email`,
          data
        );

        this.loading = false;

        this.email = undefined;
        this.$toast.success("E-mail liberado", {
          duration: 5000,
        });
      } catch (error) {
        this.loading = false;
        this.$toast.error(error?.response?.data?.error || "não foi possivel liberar o e-mail fornecido", {
          duration: 5000,
        });
      }
    },
  },
};
</script>
<style scoped>
.center {
  justify-content: center;
}
</style>